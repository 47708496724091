import React, { Component } from "react"
import styled from "styled-components"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  width: 100%;
  grid-gap: 10px;
  margin-bottom: 10px;
`

const InlineForm = styled.form`
  width: 100%;
`

const StyledInput = styled.input``

const FormText = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
`

const StyledSubmitButton = styled.button`
  width: 100%;
`

const ThanksContainer = styled.div`
  color: #155724;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  background-color: #d4edda;
  text-align: center;
  h2 {
    color: #155724;
    padding-top: 15px;
    margin-bottom: 10px;
  }
  p {
    color: #155724;
    margin-bottom: 20px;
  }
`

class NewsletterForm extends Component {
  state = {
    email: "",
    submitted: false,
    inputError: true,
    loading: false,
  }
  saveToState = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    return (
      <Container>
        {this.state.submitted ? (
          <ThanksContainer>
            <h2>Thanks!</h2>
            <p>
              We won't use your information to track you. We wouldn't even know
              how.
            </p>
          </ThanksContainer>
        ) : (
          <InlineForm
            name="newsletter"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={async e => {
              e.preventDefault()
              const form = e.target
              fetch("/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                body: encode({
                  "form-name": form.getAttribute("name"),
                  ...this.state,
                }),
              }).catch(error => alert(error))
              this.setState({ loading: true })
              setTimeout(() => this.setState({ submitted: true }), 500)
            }}
          >
            <FormText>Get updates on new posts!</FormText>
            <div>
              <StyledInput
                boolVar={this.state.inputError}
                type="email"
                name="email"
                placeholder="Email"
                id="email"
                onChange={this.saveToState}
              />
            </div>
            <div>
              <StyledSubmitButton type="submit">
                {this.state.loading ? `Loading` : `Subscribe`}
              </StyledSubmitButton>
            </div>
          </InlineForm>
        )}
      </Container>
    )
  }
}

export default NewsletterForm
