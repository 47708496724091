import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import NewsletterForm from "../components/NewsletterForm"
import BlogPostCard from "../components/BlogPostCard"

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
`

const filterDevPosts = edge =>
  process.env.NODE_ENV === "development" ||
  !edge.node.frontmatter.developmentpost

const ArchivePage = ({ data }) => (
  <>
    <SEO title="Home" />
    <Container>
      <NewsletterForm />
      {data.allMarkdownRemark.edges.filter(filterDevPosts).map(edge => (
        <BlogPostCard
          key={edge.node.frontmatter.slug}
          slug={edge.node.frontmatter.slug}
          category={edge.node.frontmatter.category}
          thumbnail={edge.node.frontmatter.thumbnail}
          posted_at={edge.node.frontmatter.posted_at}
          title={edge.node.frontmatter.title}
          description={edge.node.frontmatter.description}
        />
      ))}
    </Container>
  </>
)

export default ArchivePage

export const query = graphql`
  query ArchiveQuery {
    allMarkdownRemark(
      filter: { fields: { sourceInstanceName: { eq: "markdown-pages" } } }
      sort: { order: DESC, fields: frontmatter___posted_at }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            developmentpost
            thumbnail
            posted_at(fromNow: true)
            slug
            category
          }
        }
      }
    }
  }
`
