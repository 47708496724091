import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Thumbnail from "../components/thumbnail"

const GridImageLink = styled(Link)``

const GridItem = styled.article`
  background: rgba(50, 50, 50, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-gap: 10px;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    align-items: start;
    grid-template-columns: 1fr;
  }
`

const CategoryBadge = styled.span`
  display: inline-block;
  padding: 0.2em 0.4em;
  margin-left: 10px;
  margin-top: 3px;
  border-radius: 0.25em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #fff;
  background-color: #f8bdc4;
`

const BlogPostCard = ({
  slug,
  thumbnail,
  posted_at,
  category,
  title,
  description,
}) => (
  <GridItem>
    <GridImageLink to={`/blog/${slug}`}>
      <Thumbnail src={thumbnail} />
    </GridImageLink>
    <div>
      <span className="date">{posted_at}</span>
      {category && <CategoryBadge>{category}</CategoryBadge>}
      <h3>
        <Link to={`/blog/${slug}`}>{title}</Link>
      </h3>
      <p>{description}</p>
    </div>
  </GridItem>
)

export default BlogPostCard
