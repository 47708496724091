import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

function getFilenameFromPath(path) {
  const pieces = path.split("/")
  return pieces[pieces.length - 1]
}

const Image = styled.img`
  display: block;
  width: 100%;
  border-radius: 8px;
`;
function renderImage(file) {
  return <Image src={file.node.childImageSharp.fluid.src} />
}
const Thumbnail = function(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile(
            filter: { extension: { regex: "/jpeg|jpg|png|gif/" } }
          ) {
            edges {
              node {
                extension
                relativePath
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={({ images }) => {
        return renderImage(
          images.edges.find(
            image => image.node.relativePath === getFilenameFromPath(props.src)
          )
        )
      }}
    />
  )
}
export default Thumbnail
